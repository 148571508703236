*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Public Sans', sans-serif !important;
}
* {
    scrollbar-width: auto;
    scrollbar-color: #6d6d6d #ffffff;
}
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #6d6d6d;
    border-radius: 10px;
    border: 3px solid #ffffff;
}
.admin-container {
    padding: 25px 15px;
}
/* .admin-container {
    display: none;
}
.admin-container:target {
    display: inline;
} */
.android {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
    background-color: red;
    color: white;
    text-align: center;
}
.thehigherups-version {
    position: fixed;
    bottom: 1.5rem;
    right: 1.25rem;
    color: rgba(128, 128, 128, 0.5);
    user-select: none;
}
.userName {
    padding-right: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-lookup-input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.barely-hidden {
    color: #f5f5f5;
    /* color:#434343 */
}